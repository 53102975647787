<template>
  <CRow>
    <input ref="invoiceUpload" :model="file" style="display: none" type="file" @change="fileSelected">
    <input ref="pdfUpload" :model="file" style="display: none" type="file" @change="pdfSelected">
    <CCol col="12" xl="12">
      <h2 v-show="etiqueta">{{ etiqueta }}</h2>
      <CCard v-if="step===1">
        <CCardHeader>
          <b>Resumen de comisiones</b>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="badges"
            :fields="badgesFields"
            clickable-rows
            @row-clicked="rowClicked"
          >
            <template #reward="data">
              <td>
                  <center>{{ formatPrice(data.item.reward) }}</center>
              </td>
            </template>
          </CDataTable>
          <br><br>
          <CCard v-if="selectedItem">
            <CCardBody>
              <center><h5>{{ selectedItem.name + ' - ' + formatPrice(selectedItem.reward) }}</h5></center>
              <CDataTable
                v-if="badgeCarts && badgeCarts.length > 0"
                hover
                striped
                :items="badgeCarts"
                :fields="badgeCartsFields"
              >
                <template #total="data">
                  <td>
                      <center>{{ "$"+data.item.total }}</center>
                  </td>
                </template>
                <template #Price="data">
                  <td>
                      <center>{{ data.item.Price==0?' - ':"$"+formatPrice(data.item.Price) }}</center>
                  </td>
                </template>
                <template #quantity="data">
                  <td>
                      <center>{{ data.item.quantity==0? ' - ':formatPrice(data.item.quantity)  }}</center>
                  </td>
                </template>
                <template #comision="data">
                  <td>
                      <center>{{ "$"+data.item.comision }}</center>
                  </td>
                </template>
                <template #comisionwi="data">
                  <td>
                      <center>{{ "$"+data.item.comisionwi }}</center>
                  </td>
                </template>
                <template #comisionPorc="data">
                  <td>
                      <center>{{ data.item.comisionPorc+"%" }}</center>
                  </td>
                </template>
                <template #comisionwiPorc="data">
                  <td>
                      <center>{{ data.item.comisionwiPorc+"%" }}</center>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
          <br><br>
          <CDataTable
            hover
            striped
            :items="carts"
            :fields="fields"
          >
            <template #total="data">
              <td>
                  <center>{{ "$"+data.item.total }}</center>
              </td>
            </template>
            <template #Price="data">
              <td>
                  <center>{{ data.item.Price==0?' - ':"$"+formatPrice(data.item.Price) }}</center>
              </td>
            </template>
            <template #quantity="data">
              <td>
                  <center>{{ data.item.quantity==0? ' - ':formatPrice(data.item.quantity)  }}</center>
              </td>
            </template>
            <template #comision="data">
              <td>
                  <center>{{ "$"+data.item.comision }}</center>
              </td>
            </template>
            <template #comisionwi="data">
              <td>
                  <center>{{ "$"+data.item.comisionwi }}</center>
              </td>
            </template>
            <template #comisionPorc="data">
              <td>
                  <center>{{ data.item.comisionPorc+"%" }}</center>
              </td>
            </template>
            <template #comisionwiPorc="data">
              <td>
                  <center>{{ data.item.comisionwiPorc+"%" }}</center>
              </td>
            </template>
            <template #liquidation="data">
              <td>
                  <center>{{ "$"+formatPrice(data.item.liquidation) }}</center>
              </td>
            </template>
            <template #liquidation_m="data">
              <td>
                  <center>{{ "$"+formatPrice(data.item.liquidation_m) }}</center>
              </td>
            </template>
          </CDataTable>
          <br><br>
          <CCol col="12" v-show="readonly">
            <br/>
            <CInput 
              label="Observaciones"
              placeholder=""
              v-model="liquidation.reason"
              readonly
            />
          </CCol>
          <CCol col="12" class="mb-2" v-show="(nueva && !roleFinancing ) || (!nueva && roleFinancing)">
            <CButton
              class="float-right"
              color="success"
              @click="next()"
            >
              {{ (discountData && discountData.discount && !roleFinancing)?'Guardar':'Siguiente' }}
            </CButton>
          </CCol>
        </CCardBody>
      </CCard>
      <CCard v-if="step===2">
        <CCardHeader>
          <b>Resumen de comisiones</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="6" lg="4">
              <center><p><b>Monto total de ventas: </b>{{ liquidation.totalInvoiced }}</p></center>
            </CCol>
            <CCol col="12" sm="6" lg="4" v-if="!readonly">
              <center><p><b>Comisión generada por ventas: </b>{{ liquidation.comission }}</p></center>
            </CCol>
            <CCol col="12" sm="6" lg="4" v-if="!readonly">
              <center><p><b>Comisión sin factura: </b>{{ liquidation.comissionwi }}</p></center>
            </CCol>
          </CRow>
          <CCard>
            <CCardHeader>
              <b>Formato de facturación</b>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol col="12">
                  <p>Facturar a nombre de: <b>BSmart Cia. Ltda.</b></p>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="4">
                  <p>RUC: <b>1793041809001</b></p>
                </CCol>
                <CCol col="4">
                  <p>Dirección: <b>CHIMBORAZO N 705 Y AV. PAMPITE</b></p>
                </CCol>
                <CCol col="4">
                  <p>Teléfono: <b>025005220</b></p>
                </CCol>
                <CCol col="4">
                  <p>Correo: <b>mlascano@bsmart.ec</b></p>
                </CCol>
              </CRow>
              <CDataTable
                hover
                striped
                :items="invoiceItems"
                :fields="invoiceFields"
              >
                <template #status="data">
                  <td>
                    <CBadge :color="getBadge(data.item.status)">
                      {{ data.item.status }}
                    </CBadge>
                  </td>
                </template>
                <template #total="data">
                  <td>
                      {{ formatPrice(data.item.total) }}
                  </td>
                </template>
              </CDataTable>
              <br>
              <CCardText>
                <p>En la sección <span style="font-weight: bolder;">notas / comentarios / descripción</span> de la factura, ingresar el texto <span style="font-weight: bolder;">DESDE: {{ minDate }}</span></p>
              </CCardText>
              <br>
              <CCardText>
                <p>Nota Importante: Solo se reciben facturas electrónicas, se debe facturar con los datos presentados en el ejemplo de arriba, los valores presentados corresponden a la liquidación vigente.</p>
              </CCardText>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
      <CCard v-if="step===2">
        <CCardHeader>
          <b>Datos para liquidación</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" lg="4">
              <CSelect label="Banco" aria-label="Banco" v-model="liquidation.bank" :options="bankData" />
            </CCol>
            <CCol col="12" lg="4">
              <CSelect label="Tipo de cuenta" aria-label="Tipo de cuenta" v-model="liquidation.type" :options="accountType" />
            </CCol>
            <CCol col="12" lg="4">
              <CInput 
                label="Cuenta Bancaria"
                placeholder="Cuenta"
                v-model="liquidation.account"
                :readonly="readonly"
                required
              />
            </CCol>
            <CCol col="12" lg="4">
              <CInput 
                label="Cédula / RUC"
                placeholder="Cédula / RUC"
                v-model="liquidation.identification"
                :readonly="readonly"
                required
              />
            </CCol>
            <CCol col="12" lg="4">
              <CInput 
                label="Titular de la cuenta"
                placeholder="Titular de la cuenta"
                v-model="liquidation.name"
                :readonly="readonly"
                required
             />
            </CCol>
            <CCol col="12" lg="4" v-show="readonly">
              <p v-if="liquidation.invoice===''" style="margin: 3px;">Sin factura</p>
              <a v-if="liquidation.invoice!==''" :href="urlBase+'/'+liquidation.invoice" target="_blank" style="margin: 3px;">
                <font-awesome-icon icon="download" /> Descargar Factura XML
              </a>
              <p v-if="liquidation.pdf===''" style="margin: 3px;">Sin factura en pdf</p>
              <a v-if="liquidation.pdf!==''" :href="urlBase+'/'+liquidation.pdf" target="_blank" style="margin: 3px;">
                <font-awesome-icon icon="download" /> Descargar Factura PDF
              </a>
            </CCol>
            <CCol col="12" v-show="readonly">
              <br/>
              <CInput 
                label="Observaciones"
                placeholder="Razón"
                v-model="liquidation.reason"
                readonly
              />
            </CCol>
            <CCol col="12" lg="4" v-show="readonly">
            </CCol>
            <!-- <CCol col="12" lg="4" v-show="readonly">
              <a @click.stop.prevent="report()"><font-awesome-icon icon="download" /> Descargar Resumen</a>
            </CCol> -->
            <CCol col="12" lg="4" v-show="!readonly">
              <CButton style="margin: 3px;" color="primary" @click="$refs.invoiceUpload.click()">
                Seleccionar XML factura
              </CButton>
              <CButton style="margin: 3px;" color="primary" @click="$refs.pdfUpload.click()">
                Seleccionar PDF factura
              </CButton>
            </CCol>
          </CRow>
          <CRow style="margin-top: 25px;">
            <CCol col="12" sm="12" lg="12" v-show="alert.show">
              <CAlert :color="alert.color" dismissible>
                {{ alert.message }}
              </CAlert>
            </CCol> 
            <CCol col="12" sm="6" lg="4" v-show="nueva">
              <CButton color="primary" @click="save()">
                <CSpinner v-if="loading" variant="grow" size="sm"/>
                Guardar
              </CButton>
            </CCol>
            <CRow v-show="!nueva && roleFinancing && pending">
              <CCol col="12">
                <CInput 
                  label="Razón"
                  placeholder="Razón"
                  v-model="liquidation.reason"
              />
              </CCol>
              <CCol col="12" sm="12" lg="12">
                <CButton color="primary" @click="aprove()" style="margin-right: 10px;">
                  <CSpinner v-if="loading" variant="grow" size="sm"/>
                  Validar
                </CButton>
                <CButton color="primary" @click="reject()" style="margin-left: 10px;">
                  <CSpinner v-if="loading" variant="grow" size="sm"/>
                  Rechazar
                </CButton>
              </CCol>
            </CRow>
          </CRow>
        </CCardBody>
        <CCol col="12" class="mb-2">
          <CButton
            class="float-left"
            color="info"
            @click="step--"
          >
            Regresar
          </CButton>
        </CCol>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios';
import Resource from "@/api/resource";
import BankData from './BankData.json';
import LiquidationResource from '@/api/liquidation';
import ReportResource from '@/api/report';
import moment from "moment";

const reportResource = new ReportResource();
const liquidationResource = new LiquidationResource();
const cartResource = new Resource("cart");

export default {
  name: 'Liquidation',
  data () {
    return {
      step: 1,
      etiqueta: '',
      alert: {
        show: false,
        message: '',
        color: 'danger',
      },
      file: "",
      liquidation: {
        id: 0,
        name: '',
        email: '',
        phone: '',
        identification: '',
        totalInvoiced: 0,
        comission: 0,
        comissionwi: 0,
        iva: 0,
        retencionIva: 0,
        retencionRenta: 0,
        total: 0,
        file: null,
        pdf: null,
        status: '',
        retencionIvaPorc: 0,
        retencionRentaPorc: 0,
      },
      carts: [],
      badges: [],
      badgesFields: [
        { key: 'name', label: 'Medalla'},
        { key: 'reward', label: 'Recompensa' },
      ],
      badgeCartsFields: [
        { key: 'name', label: 'Name'},
        { key: 'identification', label: 'CI/RUC' },
        { key: 'ItemName', label: 'Producto' },
        { key: 'quantity', label: 'Cantidad' },
        { key: 'Price', label: 'Precio' },
        { key: 'total', label: 'Total' },
      ],
      loading: false,
      comissionPorc: 0,
      retencionIva: 0,
      retencionRenta: 0,
      roleFinancing: false,
      roleSeller: false,
      pending: false,
      new: false,
      readonly: true,
      activePage: 1,
      nueva: false,
      invoiceFields: [
        { key: 'description', label: 'Descripción' },
        { key: 'qty', label: 'Cantidad' },
        { key: 'price', label: 'Precio' },
        { key: 'amount', label: 'Monto' },
      ],
      accountType: [
        {value: 'CTE', label: 'Corriente'},
        {value: 'AHO', label: 'Ahorros'},
      ],
      urlBase: process.env.VUE_APP_BACKEND_URL,
      discountData: {},
      badgeCarts: [],
      selectedItem: null,
    }
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD") : "";
    },
  },
  mounted() {
    this.roleFinancing = this.$store.state.user.role == 'Financiero';
    this.roleSeller = this.$store.state.user.role == 'Vendedor';
    if(this.$route.params.id == 0){
      this.new = true;
      this.readonly = false;
      this.nueva = true;
    }else{
      this.nueva = false;
    }
    this.getItems();
    this.getDiscountData();
  },
  computed: {
    minDate() {
      var minDate = new Date();
      if(this.carts.length > 1){
        this.carts.forEach(element => {
          if(minDate > element.created_at){
            minDate = element.created_at;
          }
        });
        const description = (minDate ? moment(minDate).format("MMMM DD, YYYY") : "");        
        return description;
      }
      return "Anulado";
    },
    bankData() {
      return BankData.banks;
    },
    total() {
      return (parseFloat(this.liquidation.comission) 
        - parseFloat(this.retRenta) 
        - parseFloat(this.retIva)
        + parseFloat(this.liquidation.iva)).toFixed(2);
    },
    retIva() {
      if(this.liquidation.invoice !== ""){
        if(!this.nueva && (this.roleFinancing && this.pending)){
          if(!this.pending){
            return this.liquidation.retencionIva;
          }else{
            return (parseFloat(this.liquidation.retencionIvaPorc)*parseFloat(this.liquidation.iva)/100).toFixed(2);  
          }
        }else{
          if(!this.pending){
            return this.liquidation.retencionIva;
          }
          return 0.0;  
        }
      }else{
        return 0.0; 
      }
    },
    retRenta() {
      if(this.liquidation.invoice !== ""){
        if(!this.nueva && (this.roleFinancing && this.pending)){
          if(!this.pending){
            return this.liquidation.retencionRenta;
          }else{
            return (parseFloat(this.liquidation.retencionRentaPorc)*parseFloat(this.liquidation.comission)/100).toFixed(2);
          }
        }else{
          if(!this.pending){
            return this.liquidation.retencionRenta;
          }
          return 0.0;
        }
      }else{
        return 0.0;
      }
    },
    invoiceItems() {
      var values = [
        { description: 'SERVICIOS DE VENTA', qty: 1, price: this.liquidation.comission, amount: this.liquidation.comission},
        {description: '', qty: '', price: '',amount: ''},
        {description: '', qty: '', price: 'Subtotal', amount: this.liquidation.comission},
        {description: '', qty: '', price: 'IVA', amount: this.liquidation.iva},
        {description: '', qty: '', price: 'Total', amount: this.total},
      ];
      if(!this.nueva){
        values.push({description: '', qty: '', price: '',amount: ''},);
        values.push({description: '', qty: '', price: 'Retención renta', amount: this.retRenta});
        values.push({description: '', qty: '', price: 'Retención iva', amount: this.retIva});
      }
      return values; 
    },
    fields() {
      if(this.$route.params.id == 0){
        return [
          { key: 'name', label: 'Name'},
          { key: 'identification', label: 'CI/RUC' },
          { key: 'ItemName', label: 'Producto' },
          { key: 'quantity', label: 'Cantidad' },
          { key: 'Price', label: 'Precio' },
          { key: 'total', label: 'Total' },
          { key: 'comisionPorc', label: '% Comisión' },
          { key: 'comision', label: 'Comisión' },
        ];
      }else{
        var fields = [
          { key: 'name', label: 'Name'},
          { key: 'identification', label: 'CI/RUC' },
          { key: 'ItemName', label: 'Producto' },
          { key: 'quantity', label: 'Cantidad' },
          { key: 'Price', label: 'Precio' },
          { key: 'total', label: 'Total' },
        ];
        if(this.liquidation.user.role=="Mayorista"){
          fields.push({ key: 'liquidation_m', label: 'Liquidación' });
        }else{
          fields.push({ key: 'liquidation', label: 'Liquidación' });
        }
        return fields;
      }
    },
  },
  methods: {
    next(){
      if((this.discountData && this.discountData.discount && !this.roleFinancing)){
        this.save()
      }else{
        this.step++;
      }
    },
    async getDiscountData() {
      await cartResource.get('discount_data').then(response => {
        this.discountData = response.data;
      });
    },
    fileSelected(e){
      this.liquidation.file = e.target.files[0];
    },
    pdfSelected(e){
      this.liquidation.pdf = e.target.files[0];
    },
    getTotals(){
      this.carts.forEach(element => {
        this.liquidation.totalInvoiced += (element.Price*element.quantity);
        this.liquidation.comission += (parseFloat(this.comissionPorc)/100)*parseFloat(element.Price*element.quantity)*(parseFloat(element.commission)/100);
        this.liquidation.comissionwi += (parseFloat(this.comissionPorc)/100)*parseFloat(element.Price*element.quantity)*(parseFloat(element.commissionwi)/100);
      });
      this.badges.forEach(element => {
        this.liquidation.comission += parseFloat(element.reward);
        this.liquidation.comissionwi += parseFloat(element.reward);
      });
    },
    getTotalInvoiced(){
      this.carts.forEach(element => {
        var totalInvoiced = parseFloat(this.liquidation.totalInvoiced);
        this.liquidation.totalInvoiced = totalInvoiced + parseFloat(element.total);
        this.liquidation.totalInvoiced = this.liquidation.totalInvoiced.toFixed(2);
      });
    },
    getIva(){
      this.liquidation.iva = parseFloat(this.liquidation.comission) * 0.15;
      this.liquidation.iva = this.liquidation.iva.toFixed(2);
    },
    async getItems () {
      if(this.$route.params.id == 0){
        await liquidationResource.newLiquidation()
        .then(response => {
          this.comissionPorc = response.data.user.commission;
          this.liquidation.bank = response.data.user.invoiceBank;
          this.liquidation.type = response.data.user.invoiceType;
          this.liquidation.account = response.data.user.invoiceAccount;
          this.liquidation.identification = response.data.user.invoiceIdentification;
          this.liquidation.name = response.data.user.invoiceName;
          this.carts = response.data.carts.map(item => {
            item.total = this.formatPrice(item.quantity * item.Price);
            item.comisionPorc = this.formatPrice(item.commission * this.comissionPorc /100);
            item.comision = this.formatPrice(item.commission * this.comissionPorc * item.quantity * item.Price /10000);
            item.comisionwiPorc = this.formatPrice(item.commissionwi * this.comissionPorc /100);
            item.comisionwi = this.formatPrice(item.commissionwi * this.comissionPorc * item.quantity * item.Price /10000);
            return item;
          });
          this.badges = response.data.badges;
          this.retencionRenta = response.data.user.retencionRenta;
          this.retencionIva = response.data.user.retencionIva;
          this.getTotals();
          this.getIva();
          this.liquidation.comission = this.liquidation.comission.toFixed(2);
          this.liquidation.comissionwi = this.liquidation.comissionwi.toFixed(2);
          this.liquidation.totalInvoiced = this.liquidation.totalInvoiced.toFixed(2);
          this.carts.push({name: 'Totales', 
            identification: '',
            ItemName: '', 
            quantity: '', 
            Price: '', 
            total: this.liquidation.totalInvoiced, 
            comisionPorc: ' - ', 
            comision: this.liquidation.comission, 
            comisionwiPorc: ' - ', 
            comisionwi: this.liquidation.comissionwi});
        });
      }else{
        await liquidationResource.get(this.$route.params.id).then(response => {
          this.liquidation = response.data.liquidation;
          this.carts = response.data.carts.map(item => {
            item.total = this.formatPrice(item.quantity * item.Price);
            return item;
          });
          this.badges = response.data.badges;
          this.carts.push({name: 'Totales', 
            identification: '',
            ItemName: '', 
            quantity: '', 
            Price: '', 
            total: this.formatPrice(this.liquidation.totalInvoiced), 
            comisionPorc: ' - ', 
            liquidation: this.liquidation.comission,
            liquidation_m: this.liquidation.comission,
          });
          this.loading = false;
          this.etiqueta = this.$route.params.id + ": " + this.liquidation.status;
          if(this.liquidation.status == 'Pendiente'){
            this.pending = true;
          }
        }).catch(error => {
          this.loading = false;
        });
      }
      this.badges.push({
        name: 'Total', 
        reward: this.liquidation.comission ?? 0, 
      });
    },
    getBadge (status) {
      switch (status) {
        case 'Aprobado': return 'success'
        case 'Pagada': return 'success'
        case 'Pendiente': return 'secondary'
        case 'Inactivo': return 'warning'
        case 'Rechazado': return 'danger'
        default: 'primary'
      }
    },
    async rowClicked (item, index) {
      if(item.id){
        this.selectedItem = item;
        await liquidationResource.getCarts(item.id).then(response => {
          this.badgeCarts = response.data.carts;
        });
      }
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async report(){
      await reportResource.cart({liquidation_id: this.$route.params.id}).then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "ventas.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/liquidations'})
    },
    async save() {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.liquidation.file);
      formData.append('pdf', this.liquidation.pdf);
      formData.append('total', this.total);
      formData.append('totalInvoiced', this.liquidation.totalInvoiced);
      formData.append('retencionIva', this.liquidation.retencionIva);
      formData.append('retencionRenta', this.liquidation.retencionRenta);
      formData.append('iva', this.liquidation.iva);
      formData.append('comission', this.liquidation.comission);
      formData.append('comissionwi', this.liquidation.comissionwi);
      formData.append('bank', this.liquidation.bank);
      formData.append('type', this.liquidation.type);
      formData.append('account', this.liquidation.account);
      formData.append('identification', this.liquidation.identification);
      formData.append('name', this.liquidation.name);
      formData.append('carts', JSON.stringify(this.carts));
      
      await axios.post(process.env.VUE_APP_BACKEND_URL+'/api/liquidation',
        formData,
        {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.user.token,
            'Content-Type': 'multipart/form-data',
            'Accept':'application/json',
          },
        }
      ).then(response => {
        this.loading = false;
        this.readonly = true;
        this.alert.show = true;
        this.alert.message = "Se guardó correctamente";
        this.alert.color = "success";
        this.goBack();
      }).catch(error => {
        this.loading = false;
        this.alert.show = true;
        if(error.response.data.messageShow){
          this.alert.message = error.response.data.messageShow;
        }else{
          this.alert.message = "Por favor complete todos los campos";
        }
        this.alert.color = "danger";
      });
    },
    async aprove(){
      this.loading = true;
      this.liquidation.total = this.total;
      this.liquidation.retencionIva = this.retIva;
      this.liquidation.retencionRenta = this.retRenta;
      this.liquidation.status = 'Aprobado';
      await liquidationResource.update(this.$route.params.id, this.liquidation).then(response => {
        this.liquidation = response.data.liquidation;
        this.loading = false;
        this.alert.show = true;
        this.alert.message = "Se guardó correctamente";
        this.alert.color = "success";
        this.pending = false;
      }).catch(error => {
        this.loading = false;
        this.alert.show = true;
        this.alert.message = "Ocurrió un error";
        this.alert.color = "danger";
      });
    },
    async reject(){
      this.loading = true;
      this.liquidation.status = 'Rechazado';
      this.liquidation.total = this.total;
      this.liquidation.retencionIva = this.retIva;
      this.liquidation.retencionRenta = this.retRenta;
      await liquidationResource.update(this.$route.params.id, this.liquidation).then(response => {
        this.liquidation = response.data.liquidation;
        this.loading = false;
        this.alert.show = true;
        this.alert.message = "Se guardó correctamente";
        this.alert.color = "success";      
        this.pending = false;
      }).catch(error => {
        this.loading = false;
        this.alert.show = true;
        this.alert.message = "Ocurrió un error";
        this.alert.color = "danger";
      });
    },
  }
}
</script>
