import Resource from '@/api/resource';
import request from '@/utils/request';

class LiquidationResource extends Resource {
  constructor() {
    super('liquidation');
  }

  newLiquidation() {
    return request({
      url: '/newLiquidation',
      method: 'get',
    });
  }

  getCarts(id) {
    return request({
      url: '/getCarts/'+id,
      method: 'get',
    });
  }

  save(data) {
    return request({
      url: '/liquidation',
      method: 'post',
      params: data,
    },{
      headers: {
          'content-type': 'multipart/form-data',
      }
    });
  }

}

export { LiquidationResource as default };
